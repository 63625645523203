:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  user-select: none;
  overflow-x: hidden;
}

#root {
  flex: 1;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  @apply bg-transparent rounded-3xl;
}
*:hover::-webkit-scrollbar-thumb {
  @apply bg-primary/10;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
